<template>
  <div>
   <header class="bg-gray-600 py-20">
     <div class="transform skew-y-3 bg-gray-500 py-12 -mt-32 absolute w-full"></div>
     <div class="container mx-auto px-2 text-white">
       <p class="text-xl md:text-4xl text-red-500">ROCKET<span class="font-bold text-white">CITY</span></p>
     </div>
   </header>
   <div class="container py-10 mx-auto px-2 md:px-10">
     <h1>Misión</h1>
     <h2>Consumo local.</h2>
     <p>
       Vivimos en un mundo de cambios rápidos, donde no <b>adaptarse a las últimas tendencias</b>,
       puede suponer la pérdida de empresas y empleos en nuestra ciudad. Las grandes platadormas digitales y cadenas,
       cuentan con más recursos y dinero, para llamar la atención de los potenciales compradores
       de nuestros productos tradicionales.
     </p>
     <h2>Potenciamos y promovemos lo siguente:</h2>
     <ul class="list-disc list-inside mb-20">
       <li>El consumo local.</li>
       <li>La economía de nuestra ciudad.</li>
       <li>Apoyamos los productores locales.</li>
       <li>Turismo interactivo.</li>
       <li>Marketing responsable.</li>
     </ul>
     <h1>Visión</h1>
     <h2>Impulsor de desarrollo.</h2>
     <p>
       <span class="text-red-500 font-bold">Rocketcity</span> viene a democratizar una situación desbalanceada,
       donde los pequeños comerciantes se encuentran sin herramientas útiles para llegar a sus 
       potenciales clientes. <b>De nada sirve tener los mejores precios si nadie se entera</b>. Ahora podemos
       hacer que todo el mundo se entere a un precio muy asequible.
     </p>
     <p>
       <b>¡Y no solo eso!</b>, impulsamos el desarrollo porque ofrecemos a los usuarios de nuestra ciudad,
       una forma centralizada de acceder a toda la información y servicios disponibles, de una forma fácil y rápida. <br>
       Es democrática porque todo el mundo puede participar y opinar. En la actualidad, los ciudadanos
       no disponen de las herramientas suficientes para plantear opiniones y solicitudes a las
       administraciones. <span class="text-red-500 font-bold">Rocketcity</span> viene a cambiar esto
       facilitando la comunicación en ambas direcciones.
     </p>
     <p>
       Por otro lado, queremos ayudar a los productores locales, a que puedan vender sus productos de forma
       fácil. No queremos que se pierdan las tradiciones. Creemos en el consumo de kilómetro 0.
     </p>
   </div>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
</style>
